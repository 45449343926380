<template>
  <button class="filter">
    <div
      class="filter__content"
      :class="filtersLength > 0 && 'filter__content--filtered'"
      @click="toggleFilters"
    >
      <span class="content__label">
        <i class="content__icon icon-filter" />
        {{ $t('dashboard.filters.dimension.title') }}
        <template v-if="filtersLength"> ({{ filtersLength }}) </template>
      </span>
    </div>
  </button>
</template>

<script lang="ts">
import { mapState, mapActions } from 'pinia'
import { useFiltersStore } from '~/stores/filters'

export default {
  name: 'DimensionFiltersButton',
  computed: {
    ...mapState(useFiltersStore, [
      'customFilters',
      'standardFiltersWithSelectedValues',
      'usableScopeFilters',
      'appliedDashboardDefaultFilters'
    ]),
    filtersLength() {
      return (
        this.customFilters.length +
        this.standardFiltersWithSelectedValues.length +
        this.usableScopeFilters.length +
        this.appliedDashboardDefaultFilters.length
      )
    }
  },
  methods: {
    ...mapActions(useFiltersStore, ['toggleFilters'])
  }
}
</script>

<style lang="scss" scoped>
.filter {
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  background-color: $bg-secondary;
  @include block-shadow;

  &__content {
    display: flex;
    align-items: center;
    padding: 0 $margin-small;
    border-radius: 8px;
    background-color: transparent;
    cursor: pointer;
    user-select: none;

    .content {
      &__icon {
        @include font-icon;
        @include font-size($font-size-intermediate, 40px);
        margin-right: 4px;
      }

      &__label {
        display: flex;
        color: $text-secondary;
        @include font-text;
        @include font-size($font-size-regular, 40px);
      }
    }

    &--filtered {
      background-color: $button-secondary;

      .content {
        &__label {
          color: $text-inverse;
        }
      }
    }
  }
}
</style>
